<template>
  <div>
    <b-row class="mt-1">

      <b-col cols="12" md="6" lg="2">
        <b-form-group :label="$t('totalfloors')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.totalFloors" type="number" min="0" step="0.5"
                            />
          
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2">
        <b-form-group :label="$t('floor')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.floorNumber"
                          type="number" min="0" step="0.5"
                            />
           
        </b-form-group>
      </b-col>

      <b-col cols="12" md="12" lg="8">
        <b-form-group :label="$t('floortext')" label-for="realEstate-name">
                <b-form-input readonly  id="realEstate-name"  v-model="realEstate.floor"
                                />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="$t('livingspace')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.livingSpace" type="number" min="0"
                          step="0.01"
                            />
           
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="$t('freespace')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.freeSpace" type="number" min="0"
                          step="0.01"
                            />
         
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="$t('totalspace')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.totalSpace" type="number" min="0"
                          step="0.01"
                            />
           
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('bedrooms')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.rooms" min="0" step="0.5"
                          type="number"
                            />
            
          
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('bathrooms')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.bathrooms" min="0" step="0.5"
                          type="number"
                            />
            
          
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('seperatedtoilets')" label-for="realEstate-name">
          <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.externalToilets" min="0" step="0.5"
                        type="number"
                         />

        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('storerooms')" label-for="realEstate-name">
          <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.storeroomCount" min="0" step="0.5"
                        type="number"
                         />

        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('logias')" label-for="realEstate-name">
          <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.logiaCount" min="0" step="0.5"
                        type="number"
                         />

        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('balconies')" label-for="realEstate-name">
          <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.balconycount" min="0" step="0.5"
                        type="number"
                         />

        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('terraces')" label-for="realEstate-name">
          <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.terraceCount" min="0" step="0.5"
                        type="number"
                         />

        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('terrace/balconyspace(sqm)')" label-for="realEstate-name">
          <b-form-input readonly  id="realEstate-name" v-model="realEstate.realEstateArea.balconyTerraceSpace" min="0" step="0.01"
                        type="number"
                         />

        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>

export default {
  name: 'realEstateArea',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {

    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {


  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
