<template>
  <div>
    <b-row class="mt-1">


      <!-- RealEstate Name -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('realestateaddress')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.address" 
                           />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('housenumber')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.house" 
                           />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('apt')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.apt" 
                           />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('Zip')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.zip" 
                           />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('city')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.city" 
                           />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('location')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.location" 
                           />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('state/province')" label-for="realEstate-name">
            <b-form-input readonly  id="realEstate-name" v-model="realEstate.state" 
                           />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('country')" label-for="user-country">
          <b-form-input readonly v-model="realEstate.country"></b-form-input>
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>

export default {
  name: 'realEstateGeo',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {


    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {


  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
