<template>
  <div id="todolist" >
    <h1>
      {{ $t('todolist') }}
      <span>Get things done, one item at a time.</span>
    </h1>

    <template v-if="todo.length">
      <transition-group class="mb-3" name="todolist" tag="ul">
        <li v-for="item in todoList" :key="item.id" :class="item.done ? 'done' : ''">
          <div class="label-description">
            <div class="label" style="margin-bottom:3px; font-size: 18px;font-weight: bold">{{ item.label }}</div>
            <div class="label description">{{ item.description }}</div>
            <b-badge class="w-100" variant="primary" >{{ item.tag }}</b-badge>
          </div>
          <div class="actions">
            <b-button
                size="md"
                class="p-1 btn-picto mr-1"
                disabled :aria-label="item.done ? 'Undone' : 'Done'"
                :variant="!item.done ? 'danger' : 'success'"
                :title="item.done ? 'Undone' : 'Done'"
            >
              <i
                  aria-hidden="true"
                  class="fas"
                  :class="{ 'fa-check': item.done, 'fa-check-circle': !item.done }"
              ></i>
            </b-button>
            <b-button
                size="md"
                class="p-1 btn-picto"
                disabled
                aria-label="Delete"
                title="Delete"
            >
              <i aria-hidden="true" class="fas fa-trash"></i>
            </b-button>
          </div>
        </li>
      </transition-group>

    </template>
    <p v-else class="emptylist">{{ $t('yourtodolistisempty') }}</p>

  </div>
</template>

<script>

export default {
  name: 'TodoList',
  data() {
    return {
      isFormValid: false,
      newitem: '',
      newDescription: '',
      sortByStatus: false,
      todo: [],
    };
  },
  created() {

    this.todo = this.value.todoList || [];

  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    todoList() {
      if (!this.sortByStatus) {
        return this.todo;
      }

      const doneArray = this.todo.filter((item) => item.done);
      const notDoneArray = this.todo.filter((item) => !item.done);

      return [...notDoneArray, ...doneArray];
    },
  },
  methods: {},

};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@keyframes strikeitem {
  to {
    width: calc(100% + 1rem);
  }
}

#todolist {
  margin: 4rem auto;
  padding: 2rem 3rem 3rem;
  max-width: 80%;
  box-shadow: -20px -20px 0px 0px rgba(100, 100, 100, .1);
}

#todolist h1 {
  /*text-align:center;*/
  font-weight: normal;
  font-size: 2.6rem;
  letter-spacing: 0.05em;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

#todolist h1 span {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.7rem;
  margin-left: 3px;
  margin-top: 0.2rem;
}

#todolist .emptylist {
  margin-top: 2.6rem;
  text-align: center;
  letter-spacing: .05em;
  font-style: italic;
  opacity: 0.8;

}

#todolist ul {
  margin-top: 2.6rem;
  list-style: none;
}

#todolist .todolist-move {
  transition: transform 1s;
}

#todolist li {
  display: flex;
  margin: 0 -3rem 4px;
  padding: 1.1rem 3rem;
  justify-content: space-between;
  align-items: center;
  background: rgb(216,216,216);
}

#todolist .actions {
  flex-shrink: 0;
  padding-left: 0.7em;
}

#todolist .label {
  position: relative;
  transition: opacity .2s linear;
}

#todolist .done .label {
  opacity: .3;
}

#todolist .done .label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -.5rem;
  display: block;
  width: 0%;
  height: 1px;
  background: #FFF;
  animation: strikeitem .3s ease-out 0s forwards;
}

#todolist .btn-picto {
  border: none;
  background: none;
  -webkit-appearance: none;
  cursor: pointer;
  color: #FFF;
}


</style>
