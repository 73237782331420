<template>
  <div>
    <b-row class="mt-1">
      <!-- Branch -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('propertytype')" label-for="realEstate-branch">
          <b-form-input

              v-model="realEstate.propertyType"
              readonly

          />
        </b-form-group>
      </b-col>

      <!-- RealEstate type -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('usage')" label-for="realEstate-type">
          <b-form-input
              readonly
              v-model="usage"
          />
        </b-form-group>
      </b-col>

      <!-- RealEstate type -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('marketingtype')" label-for="realEstate-type">
          <b-form-input

              v-model="marketingType"
              readonly
          />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">

        <b-form-group :label="$t('Status')" label-for="realEstate-branch">
          <b-form-input

              v-model="realEstate.status"
              readonly

          />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('buildyear')" label-for="realEstate-sell-price">
          <b-form-input readonly id="realEstate-sell-price" v-model="realEstate.realEstateState.buildYear"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('state')" label-for="realEstate-sell-price">
          <b-form-input readonly id="realEstate-sell-price" v-model="realEstate.realEstateState.state"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('buildtype')" label-for="realEstate-sell-price">
          <b-form-input readonly id="realEstate-sell-price" v-model="realEstate.realEstateState.ageType"
          />
        </b-form-group>
      </b-col>
      <!-- Sell Price -->
      <b-col cols="12" md="6" lg="2">
        <b-form-group :label="$t('availablefrom')" label-for="realEstate-sell-price">
          <b-form-input readonly id="realEstate-sell-price" v-model="realEstate.realEstateManagement.availableFrom"
          />
        </b-form-group>
      </b-col>


      <!-- Sell Price -->
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('rentperiod(months,yearsetc)')" label-for="realEstate-sell-price">
          <b-form-input readonly id="realEstate-sell-price" v-model="realEstate.realEstateManagement.maxRentPeriod"
          />
        </b-form-group>
      </b-col>

      <!-- Sell Price -->
      <b-col cols="12" md="6" lg="2">
        <b-form-group :label="$t('maxrenttime')" label-for="realEstate-sell-price">
          <b-form-input readonly id="realEstate-sell-price" v-model="realEstate.realEstateManagement.maxRentTime"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="2">
        <div class="mt-2">
          <b-form-group :description="$t('addressvisible') ">

            <b-form-checkbox disabled
                v-model="realEstate.realEstateManagement.addressVisible"
                name="check-button" switch
            >
            </b-form-checkbox>
          </b-form-group>

        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'realEstateManagement',
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {

    ...mapGetters('realEstates', ['allStatus']),
    usage: {
      get() {

        let usage = this.value.usageType;

        if (usage.living === true) return "Living";
        if (usage.business === true) return "Business";
        if (usage.investment === true) return "Investment";

        return "No data"
      },
    },marketingType: {
      get() {

        let marketingType = this.value.marketingType;


        if (marketingType.purchase === true) return "Purchase";
        if (marketingType.rent === true) return "Rent";

        return "No data"
      },
    },
    allStatuses() {
      return Object.values(this.allStatus)
    },

    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {}
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
