<template>
  <div>
    <b-table
        v-if="realEstate.realEstateExpenses!=null"
        ref="product-table"
        striped
        hover
        responsive
        v-bind:items="realEstate.realEstateExpenses"
        v-bind:fields="fields"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mx-1"></b-spinner>
          <strong>{{ $t('Loading') }}...</strong>
        </div>
      </template>

      <template #cell(year)="data">
        <b-badge variant="primary">{{ (data.item.year) }} - {{ getMonthName(data.item.month) }}</b-badge>
      </template>

      <template #cell(rent)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(insurance)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(internet)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(gas)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(electricity)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(cleaning)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(damage)="data">
        {{ convertToEURO(data.value) }}
      </template>

      <template #cell(management)="data">
        {{ convertToEURO(data.value) }}
      </template>
      <template #cell(commission)="data">
        {{ convertToEURO(data.value) }}
      </template>

      <template #cell(total)="data">
        <b-badge variant="success">{{
            convertToEURO(
                data.item.rent +
                data.item.insurance +
                data.item.gas +
                data.item.internet +
                data.item.cleaning +
                data.item.management +
                data.item.commission +
                data.item.damage +
                data.item.electricity)
          }}
        </b-badge>
      </template>

    </b-table>

  </div>
</template>

<script>

export default {
  name: 'realEstateExpenses',
  data() {
    return {
      showModal: false,
      fields: [],

      months: [],
      years: [],
      monthNames : [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  created() {

    this.setFields();

  },
  mounted() {
    this.getMonthOptions();
  },
  computed: {


    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getMonthName(month) {
      return this.monthNames[month - 1]; // Subtract 1 to match array index
    },
    convertToEURO(someNumber) {
      return new Intl.NumberFormat("de-AT", {
        style: "currency",
        currency: "EUR",
      }).format(someNumber);
    },
    setFields() {
      let $this = this
      this.fields = [
        {
          key: 'year',
          label: $this.$t('date'),
          sortable: true
        },

        {
          key: 'rent',
          label: $this.$t('Rent'),
          sortable: true
        },

        {
          key: 'internet',
          label: $this.$t('internet'),
          sortable: true
        },

        {
          key: 'gas',
          label: $this.$t('gas'),
          sortable: true
        },

        {
          key: 'electricity',
          label: $this.$t('electricity'),
          sortable: true
        },

        {
          key: 'insurance',
          label: $this.$t('insurance'),
          sortable: true
        },
        {
          key: 'damage',
          label: $this.$t('damage'),
          sortable: true
        },
        {
          key: 'cleaning',
          label: $this.$t('cleaning'),
          sortable: true
        },
        {
          key: 'commission',
          label: $this.$t('commission'),
          sortable: true
        },
        {
          key: 'management',
          label: $this.$t('management'),
          sortable: true
        },
        {
          key: 'total',
          label: $this.$t('Total'),
          sortable: true
        },

      ]
    },
    getMonthOptions() {
      for (let i = 0; i < 12; i++) {
        const monthObj = {
          value: i+1,
          label: new Date(0, i).toLocaleString('default', {month: 'long'})
        };
        this.months.push(monthObj);

        const startYear = 2020;
        const endYear = 2030;

        for (let year = startYear; year <= endYear; year++) {
          this.years.push(year);
        }
      }
    },


  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
