<template>
  <div>
    <div v-if="realEstate.marketingType!=null&&realEstate.marketingType.rent!=null &&realEstate.marketingType.rent===true">
      <b-row class="mt-1">
        <b-col cols="12" md="6" lg="2">
          <b-form-group :label="$t('operatingcost')" label-for="realEstate-buy-price">
            <b-form-input readonly   id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.operatingCost"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="2">
          <b-form-group :label="$t('rentpersm')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentPerSqm"
                           />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="2">
          <b-form-group :label="$t('rentnetto')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentNetto"
                           />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="3">
          <b-form-group :label="$t('rentbrutto')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentBrutto"
                           />
          </b-form-group>
        </b-col>


        <b-col cols="12" md="6" lg="3">
          <b-form-group :label="$t('renttotal')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.rentTotal"
                           />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('rentdeposit')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.deposit"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('rentdeposittext')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.depositText"
                           />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('servicefees')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number"  v-model="realEstate.realEstatePrices.serviceFee"
                           />
          </b-form-group>
        </b-col>

      </b-row>
    </div>
    <div
        v-else-if="realEstate.marketingType!=null&& realEstate.marketingType.purchase!=null && realEstate.marketingType.purchase===true">
      <b-row class="mt-1">

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('pricenetto')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.priceNetto"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('pricebrutto')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.priceBrutto"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('pricetotal')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.price"
                           />
          </b-form-group>
        </b-col>


        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('lawyerfee')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.lawyerFee"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('landregisterfee')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.landRegisterFee"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('groundtax')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstatePrices.groundTax"
                           />
          </b-form-group>
        </b-col>


      </b-row>
    </div>
    <div>
      <b-row class="mt-1">



      <b-col cols="12" md="6" lg="4">
        <b-form-group :label="$t('incourtage')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.inCourtage"
                         />
        </b-form-group>
      </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('outcourtage')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.outCourtage"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('commission')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number"  v-model="realEstate.realEstatePrices.commission"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('commissiontext')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price"  v-model="realEstate.realEstatePrices.commissionText"
                           />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('processingfee')" label-for="realEstate-buy-price">
            <b-form-input readonly  id="realEstate-buy-price" type="number"  v-model="realEstate.realEstatePrices.processFee"
                           />
          </b-form-group>
        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>

export default {
  name: 'realEstatePrices',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {

    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {


  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
