<template>
  <div class="realEstate-page">
    <div v-if="loaded === true">
      <!-- RealEstate statistics -->
      <b-tabs class="client-panel-tabs tabs-title" nav-class="d-inline-flex" v-model="tabIndex">
        <!--Pictures, Managemnt details, Geografic details,Areas details -->
        <b-tab :title="$t('Management')">
          <div>

            <!--Managemnt details -->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ $t('Management') }}
                    </h4>
                  </div>
                  <real-estate-management v-model="realEstate"/>
                </div>
              </b-card-body>
            </b-card>
            <!--Areas details (size and rooms) -->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <i class="fas fa-ruler-combined"></i>
                    <h4 class="mb-0 ml-50">
                      {{ $t('Areas') }}
                    </h4>
                  </div>
                  <real-estate-area v-model="realEstate"/>
                </div>
              </b-card-body>
            </b-card>
            <!--Geografic details -->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <i class="fas fa-map-marked-alt"></i>
                    <h4 class="mb-0 ml-50">
                      {{ $t('Geografic') }}
                    </h4>
                  </div>
                  <real-estate-geo v-model="realEstate"/>
                </div>
              </b-card-body>
            </b-card>

          </div>
        </b-tab>
        <b-tab :title="$t('pictures')">

          <!--Pictures-->
          <b-card no-body class=" card align-items-center card-stats w-100">

            <b-card-body>
              <lingallery :iid.sync="currentId" :width="width" :height="height" :items="images"/>
            </b-card-body>
          </b-card>

        </b-tab>
        <b-tab :title="$t('docs')">

          <b-card style="height: auto;" border-variant="warning">
            <div>
              <div class="files-data">
                <b-table-simple outlined>
                  <b-tr>
                    <b-th class="text-center" style="width: 20%">{{ $t('File') }}</b-th>
                    <b-th class="text-center" style="width: 20%">{{ $t('Tag') }}</b-th>
                    <b-th class="text-center" style="width: 20%">{{ $t('Note') }}</b-th>
                    <b-th class="text-center" style="width: 15%">{{ $t('Operations') }}</b-th>
                  </b-tr>
                </b-table-simple>
                <perfect-scrollbar class="position-relative " style="height: 250px">
                  <b-table-simple outlined>
                    <b-tr v-for="(file) in (realEstate.files || [])" v-bind:key="file.id"
                          style="white-space: nowrap;text-overflow: ellipsis" v-show="file.active">
                      <b-td style="width: 20%">
                        <i v-bind:class="iconByExt(file.filename)" class="mx-1"
                           style="font-size: 20px"></i>{{ file.filename }}
                      </b-td>
                      <b-td class="text-center" style="width: 20%">
                        <v-select :options="fileTags" :reduce="(tag) => tag.value"
                                  label="title" v-model="file.tag"
                                  disabled
                                  append-to-body >
                          <template v-slot:option="option">
                            <i class="d-inline-block text-left" style="width: 30px" :class="option.icon"></i>
                            <span>{{ option.title }}</span>
                          </template>
                        </v-select>
                      </b-td>
                      <!--                        <b-td class="text-center" style="width: 20%">
                                                <b-form-textarea v-model="file.clientNote" @change="()=>submitDocumentFile(index)"
                                                                 size="sm"></b-form-textarea>
                                              </b-td>-->
                      <b-td class="text-center" style="width: 20%">
                        <b-form-textarea v-model="file.notes" disabled
                                         size="sm"></b-form-textarea>
                      </b-td>

                      <b-td class="text-center" style="width: 15%">
                        <b-link><i class="icon fas fa-download  w-auto" @click="()=>onDownloadFile(file.id,realEstate.id)"></i>
                        </b-link>
                      </b-td>
                    </b-tr>
                  </b-table-simple>
                </perfect-scrollbar>
              </div>
            </div>
          </b-card>
        </b-tab>
        <!--furniture details, Prices details  -->
        <b-tab :title="`${$t('prices')}`">
          <div>
            <!-- Prices details-->
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <i class="fas fa-euro-sign"></i>
                    <h4 class="mb-0 ml-50">
                      {{ $t('prices') }}
                    </h4>
                  </div>
                  <real-estate-prices v-model="realEstate"/>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-tab>
        <b-tab :title="`${$t('furniture')} `">
          <div>
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ $t('furniture') }}
                    </h4>
                  </div>
                  <real-estate-furniture v-model="realEstate.realEstateFurnishing"/>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </b-tab>
        <!--Contact details  -->
        <b-tab :title="$t('contact')">
          <b-col cols="12" class="col-12">
            <b-card no-body class="card-stats ">
              <b-card-body>
                <div>
                  <!-- Header: Personal Info -->
                  <div class="d-flex">
                    <feather-icon icon="PackageIcon" size="19"/>
                    <h4 class="mb-0 ml-50">
                      {{ $t('contact') }}
                    </h4>
                  </div>
                  <real-estate-contact v-model="realEstate"/>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-tab>

        <b-tab v-if="realEstate.marketingType.rent" :title="$t('Treasure Rent')">

          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>
                <!-- Header: Personal Info -->
                <div class="d-flex">
                  <feather-icon icon="PackageIcon" size="19"/>
                  <h4 class="mb-0 ml-50">
                    {{ $t('Treasure Rent') }}
                  </h4>
                </div>
                <real-estate-treasure-rent v-model="realEstate"/>
              </div>
            </b-card-body>
          </b-card>

        </b-tab>

        <b-tab v-if="realEstate.marketingType.rent" :title="$t('Expenses')">

          <b-card no-body class="card-stats ">
            <b-card-body>
              <div>

                <real-estate-expenses v-model="realEstate"/>
              </div>
            </b-card-body>
          </b-card>

        </b-tab>

        <b-tab :title="$t('Todo List')">

          <b-card   class="card-stats">
            <real-estate-todo v-model="realEstate" @change="()=>realEstateUpdated=true"/>

          </b-card>
        </b-tab>


        <b-tab :title="$t('calendar')">


        </b-tab>
      </b-tabs>

      <template v-if="tabIndex === 9">
        <calendar-rent :property-id="realEstate.id"></calendar-rent>

      </template>
      <template v-if="realEstate.realEstateTreasure != null && realEstate.realEstateTreasure.review != null && thisPage">
        <script
            type="application/javascript"
            src="https://www.revyoos.com/js/widgetBuilder.js"
            :data-revyoos-widget="realEstate.realEstateTreasure.review"
            ref="revyoosScript"
        ></script>
      </template>
    </div>
    <div v-else>
      <div class="d-flex align-items-center" style="min-height: 600px">
        <div class="d-inline-block w-100 text-center">
          <b-spinner class="mx-1" variant="primary"/>
          <strong>{{ $t('Loading') }}...</strong>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable vue/no-unused-components */

import {mapActions, mapGetters} from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import RealEstateGeo from '@/components/panels/realEstates/realEstateGeo'
import RealEstateFurniture from '@/components/panels/realEstates/realEstateFurniture'
import RealEstatePrices from '@/components/panels/realEstates/realEstatePrices'
import RealEstateContact from '@/components/panels/realEstates/realEstateContactPerson'
import RealEstateArea from '@/components/panels/realEstates/realEstateAreas'
import RealEstateManagement from '@/components/panels/realEstates/realEstateManagement'
import {mergeDeep} from "@/Plugins/custom-prototypes";
import RealEstateTreasureRent from "@/components/panels/realEstates/realEstateTreasureRent";
import CalendarRent from "@/views/calendar/CalendarRent";
import Lingallery from "lingallery";
import RealEstateExpenses from "@/components/panels/realEstates/realEstateExpenses";
import RealEstateTodo from "@/components/panels/realEstates/realEstateTodo";

export default {
  name: 'editRealEstate',
  components: {
    RealEstateGeo,
    RealEstateArea,
    RealEstateFurniture,
    RealEstateContact,
    RealEstatePrices,
    RealEstateManagement,
    RealEstateExpenses,
    RealEstateTodo,
    vueDropzone: vue2Dropzone,
    RealEstateTreasureRent,
    CalendarRent,
    Lingallery
  },
  data() {
    return {
      loaded: false,
      uploadFile: false,
      thisPage:false,
      error: null,
      realEstate: null,
      tabIndex: 0,
      saveButton: 0,
      requestId: null,
      realEstateUpdated: false,
      dropzoneOptions: {},
      width: 600,
      height: 400,
      currentId: null,
      //Listeners
      listeners: {},
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
  },
  computed: {
    ...mapGetters('realEstates', ['fileTags']),

    images: {
      get() {

        if (this.realEstate.attachments == null) return null
        let base = 'https://treasurehome.at'
        return this.realEstate.attachments.filter(attachment => attachment != null && (attachment.attachmentGroup === 'BILD'
            || attachment.attachmentGroup === 'TITELBILD'
            || attachment.attachmentGroup === 'GRUNDRISS')
        ).map(attachment => {
          let link = attachment.attachmentUrl.startsWith("http") ? attachment.attachmentUrl : base + attachment.attachmentUrl;
          return {src: link, thumbnail: link}

        })
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  created() {
    if (this.$route.params.id == null) {
      return this.error = 'Invalid realEstate id'
    }
    this.id = this.$route.params.id;
    this.requestId = this.$route.params.requestId;
    this.onLoad()
  },
mounted() {
  this.thisPage=true;

},
  beforeDestroy() {
    console.log("beforeDestroy")
    window.document.getElementById('scp_iframe_general').remove();
    this.$root.$emit("layoutOptions", {logo: null, logoMobile: null});
  },
  beforeUnmount() {
    this.thisPage=false;
    console.log("beforeUnmount")
    window.document.getElementById('scp_iframe_general').remove();
  },
  methods: {
    ...mapActions('realEstates', ['getDocumentRealEstate']),

    ...mapActions('realEstates', {
      _getRealEstate: 'getById',
    }),

    onDownloadFile(fileId,realEstateId) {
      let $this = this;

      this.getDocumentRealEstate({fileId: fileId,realEstateId:realEstateId})
          .then(file => {
                $this.asyncDownloadFile(file)})

    },
    iconByExt(file) {
      if (file == null) {
        return {fas: true, 'fa-file-alt': true};
      }
      let ext = file.substr(file.lastIndexOf('.') + 1)
      if (ext === 'pdf') {
        return {far: true, 'fa-file-pdf': true}
      }
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gift' || ext === 'bmp') {
        return {far: true, ' fa-file-image': true}
      }
      if (ext === 'doc' || ext === 'docx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'xls' || ext === 'xlsx') {
        return {far: true, ' fa-file-word': true}
      }
      if (ext === 'zip' || ext === 'rar' || ext === '7zip') {
        return {far: true, ' fa-file-archive': true}
      }
      return {fas: true, 'fa-file-alt': true}
    },
    onLoad() {
      this.loaded = false

      this.loadRealEstate()

    },
    loadDropZone() {
      let $this = this;
      this.dropzoneOptions = {
        url: window.config.apiUrl + 'realEstate/upload/img',
        headers: {'Authorization': `Bearer ${localStorage.Bearer}`, 'realEstateId': $this.realEstate.id},
        createImageThumbnails: false,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        maxFilesize: 50000,
        acceptedFiles: 'image/*,video/*,.mp4',

      }
    },
    updatedHours: function (val) {
      // do whatever you want here
      console.log('updatedHours', val)
    },
    loadRealEstate() {
      let $this = this
      $this.loaded = false
      console.log('gettong realEstate ')

      this._getRealEstate($this.id)
          .then((realEstate) => {
            let defaultRealEstate = {
              contactPerson: {},
              realEstateState: {},
              realEstateManagement: {},
              furnishes: {},
              realEstateTexts: {},
              realEstatePrices: {},
              realEstateTreasure: {},
              attachments: []
            };

            $this.realEstate = mergeDeep(realEstate, defaultRealEstate)
            console.log('the realEstate is', $this.realEstate)
            //Set page as loaded
            $this.loaded = true
            $this.realEstateUpdated = false;
            $this.checkNullObjects(realEstate);

            $this.loadDropZone();
          }, (error) => {
            $this.error = error
          })
    },
    checkNullObjects(realEstate) {
      if (realEstate.realEstateManagement == null) {
        realEstate.realEstateManagement = {}
      }
      if (realEstate.realEstateState == null) {
        realEstate.realEstateState = {}
      }
      if (realEstate.contactPerson == null) {
        realEstate.contactPerson = {}
      }
      if (realEstate.attachments == null) {
        realEstate.attachments = []
      }
      if (realEstate.realEstateFurnishing == null) {
        realEstate.realEstateFurnishing = {}
      }
      if (realEstate.realEstateTexts == null) {
        realEstate.realEstateTexts = {}
      }
      if (realEstate.realEstatePrices == null) {
        realEstate.realEstatePrices = {}
      }

    }
    ,
    uploadComplete(file, response) {
      this.loadRealEstate()

      console.log('uploadComplete', {file, response})
    },
    uploadSuccess(file, response) {
      try {
        if (response.data == null || response.data['RealEstate'] == null)
          return;
        this.realEstate.images = response.data['RealEstate']['images'];
      } catch (e) {
        console.error('uploadSuccess error', e)
      }
    },

    validate(field) {
      return this.$v.realEstate[field] != null && this.$v.realEstate[field].$error === true
    },


  },

}
</script>

<style>
.realEstate-page {
  padding: 30px;
}

.realEstate-page .update-realEstate {
  position: fixed;
  bottom: 5%;
  z-index: 100;
  transition: all 0.5s ease;
  right: 79px;
  opacity: 0;
}

.realEstate-page .update-realEstate.active {
  opacity: 1;
}

.realEstate-page .update-realEstate .btn-primary {
  -webkit-box-shadow: 0 1px 20px 1px #7367f0 !important;
  box-shadow: 0 1px 20px 1px #7367f0 !important;
}

.realEstate-page .update-realEstate .btn-warning {
  -webkit-box-shadow: 0 1px 20px 1px #ff8510 !important;
  box-shadow: 0 1px 20px 1px #ff8510 !important;
}

.realEstate-page .dz-preview, .dz-file-preview {
  display: none;
}

.bg-c7b197 {
  background-color: rgba(204, 192, 178, 0.55);
}
</style>
