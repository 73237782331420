<template>
  <div class="mt-2">
    <b-row>
      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-bath"></i> {{ $t("bath") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.bath.dusche">{{ $t("shower") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.bath.wanne">{{ $t("tub") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.bath.fenster">{{ $t("Window") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.bath.bidet">{{ $t("Bidet") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.bath.pissoir">{{ $t("Pissoir") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-utensils"></i> {{ $t("kitchen") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.kitchen.ebk">{{ $t("ebk") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.kitchen.offen">{{ $t("open") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.kitchen.pantry">{{ $t("pantry") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ $t("Gastronomy") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.gastronomy.bar">{{ $t("Bar") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.gastronomy.hotelrestaurant">{{ $t("Hotel-Restaurant") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ $t("Security") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.security.alarmanlage">{{ $t("Alarm system") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.security.kamera">{{ $t("Camera") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.security.polizeiruf">{{ $t("Police call") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-temperature-low"></i> {{ $t("heating System") }}</h5>
        <div>
          <b-form-checkbox disabled  inline v-model="furnishEdit.heatingSystem.fern">{{
              $t("district heating")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  inline v-model="furnishEdit.heatingSystem.etage">{{ $t("etage heating") }}</b-form-checkbox>
          <b-form-checkbox disabled  inline v-model="furnishEdit.heatingSystem.zentral">{{
              $t("central heating")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  inline v-model="furnishEdit.heatingSystem.ofen">{{ $t("oven") }}</b-form-checkbox>
          <b-form-checkbox disabled  inline v-model="furnishEdit.heatingSystem.fussboden">{{
              $t("underfloor")
            }}
          </b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-sort-numeric-up-alt"></i> {{ $t("elevator") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.elevator.personen">{{ $t("people elevator") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.elevator.lasten">{{ $t("freight elevator") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-shapes"></i> {{ $t("roofShape") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.roofShape.flachdach">{{ $t("flat roof") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.roofShape.krueppelwalmdach">{{
              $t("half-hipped roof")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.roofShape.mansarddach">{{ $t("mansard roof") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.roofShape.pultdach">{{ $t("pent roof") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.roofShape.satteldach">{{ $t("gable roof") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.roofShape.walmdach">{{ $t("hip roof") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.roofShape.pyramidendach">{{ $t("pyramid roof") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-hammer"></i> {{ $t("construction") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.construction.massiv">{{ $t("massive") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.construction.fertigteile">{{ $t("finished parts") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.construction.holz">{{ $t("wood") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-building"></i> {{ $t("Energy type") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.niedrigenergie"> {{ $t("low energy") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.passivhaus"> {{ $t("passive house") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.neubaustandard"> {{
              $t("new building std.")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.minergiebauweise"> {{
              $t("minergie const.")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.minergiezertifiziert"> {{
              $t("Minergie certified")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.kfw40"> {{ $t("kfw40") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.kfw55"> {{ $t("kfw55") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.kfw60"> {{ $t("kfw60") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.energyType.kfw70"> {{ $t("kfw70") }}</b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ $t("AlignBalconyTerrace") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.sued">{{ $t("south") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.nord">{{ $t("north") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.ost">{{ $t("east") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.west">{{ $t("west") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.nordost">{{
              $t("nord east")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.nordwest">{{
              $t("north west")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.suedost">{{
              $t("south east")
            }}
          </b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.alignmentbalconyterrace.suedwest">{{
              $t("south west")
            }}
          </b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fab fa-delicious"></i> {{ $t("Boden") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.parquet">{{ $t("parquet") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.fertigparkett">{{ $t("finished parquet") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.fliesen">{{ $t("tiles") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.stein">{{ $t("stone") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.teppich">{{ $t("carpet") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.laminat">{{ $t("laminate") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.dielen">{{ $t("plank floor") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.kunststoff">{{ $t("plastic") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.estrich">{{ $t("screed") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.doppelboden">{{ $t("raised floor") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.linoleum">{{ $t("linoleum") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.marmor">{{ $t("marble") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.terrakotta">{{ $t("terracotta") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.floor.granit">{{ $t("granite") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-fire"></i> {{ $t("firing") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.oel">{{ $t("oil") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.gas">{{ $t("gas") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.elektro">{{ $t("electro") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.alternativ">{{ $t("alternative") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.solar">{{ $t("solar") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.erdwaerme">{{ $t("geothermal") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.luftwp">{{ $t("air") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.fern">{{ $t("distant heating") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.block">{{ $t("block") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.wasserelektro">{{ $t("water electro") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.pellet">{{ $t("pellet") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.kohle">{{ $t("coal") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.holz">{{ $t("wood") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.beacon.fluessiggas">{{ $t("liquid gas") }}</b-form-checkbox>
        </div>
      </b-col>

      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 style="white-space: nowrap" class="text-capitalize"><i class="fab fa-houzz"></i> {{ $t("Expansion stage") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.expansionStage.bausatzhaus">{{ $t("Kit house") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.expansionStage.ausbauhaus">{{ $t("Expansion house") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.expansionStage.schluesselfertigmitkeller">{{ $t("Turnkey with basement") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.expansionStage.schluesselfertigohnebodenplatte">{{ $t("Turnkey without floor slab") }}</b-form-checkbox>
          <b-form-checkbox disabled  v-model="furnishEdit.expansionStage.schluesselfertigmitbodenplatte">{{ $t("Turnkey with floor slab") }}</b-form-checkbox>
        </div>
      </b-col>
<!--      <b-col cols="12" md="6" lg="2" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-parking"></i> {{ $t("Parking type") }}</h5>
        <div>
          <b-form-checkbox disabled  v-model="furnishEdit.parkingType.underground">{{ $t("underground") }}</b-form-checkbox>
        </div>
      </b-col>-->
      <b-col cols="12" lg="12" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-couch"></i> {{ $t("furnished") }}</h5>
        <div>
          <span class="mr-1">{{ $t("Furnitures") }}: </span>
          <b-form-radio disabled inline v-model="furnishEdit.furnished.moeb" name="furnished-moeb" value="VOLL">
            {{ $t("FULL") }}
          </b-form-radio>
          <b-form-radio disabled inline v-model="furnishEdit.furnished.moeb" name="furnished-moeb" value="TEIL">
            {{ $t("PARTIAL") }}
          </b-form-radio>
          <b-form-radio disabled inline v-model="furnishEdit.furnished.moeb" name="furnished-moeb" :value=null>
            {{ $t("NONE") }}
          </b-form-radio>
        </div>
      </b-col>

      <b-col cols="12" lg="12" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-couch"></i> {{ $t("Ausstatt Kategorie") }}</h5>
        <div>
          <span class="mr-1">{{ $t("Quality") }}: </span>
          <b-form-radio disabled inline v-model="furnishEdit.ausstattKategorie" name="furnished-ausstattKategorie" value="STANDARD">
            {{ $t("STANDARD") }}
          </b-form-radio>
          <b-form-radio disabled inline v-model="furnishEdit.ausstattKategorie" name="furnished-ausstattKategorie" value="GEHOBEN">
            {{ $t("ELEVATED") }}
          </b-form-radio>
          <b-form-radio disabled inline v-model="furnishEdit.ausstattKategorie" name="furnished-ausstattKategorie" value="LUXUS">
            {{ $t("LUXURY") }}
          </b-form-radio>
        </div>
      </b-col>
      <b-col cols="12" lg="12" class="mb-3">
        <h5 class="text-capitalize"><i class="fas fa-couch"></i> {{ $t("Basement") }}</h5>
        <div>
          <span class="mr-1">{{ $t("Quality") }}: </span>
          <b-form-radio disabled inline v-model="furnishEdit.basement.keller" name="furnished-basement" value="JA">
            {{ $t("Yes") }}
          </b-form-radio>
          <b-form-radio disabled inline v-model="furnishEdit.basement.keller" name="furnished-basement" value="NEIN">
            {{ $t("No") }}
          </b-form-radio>
          <b-form-radio disabled inline v-model="furnishEdit.basement.keller" name="furnished-basement" value="TEIL">
            {{ $t("Partial") }}
          </b-form-radio>
        </div>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.cableSatTv"><i class="fas fa-tv"></i>
              {{ $t("CableSatTv") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.wheelchairAccessible"><i class="fab fa-accessible-icon"></i>
              {{ $t("Wheelchair accessible") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.seniorFriendly"><i class="fas fa-walking"></i>
              {{ $t("Senior friendly") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.storageRoom"><i class="fas fa-warehouse"></i>
              {{ $t("Storage room") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.bikeRoom"><i class="fas fa-bicycle"></i> {{ $t("Bike room") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.washdryroom"><i class="fas fa-soap"></i> {{
                $t("Wash-dryroom")
              }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.conservatory"><i class="fas fa-question"></i>
              {{ $t("Conservatory") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.umtsReception"><i class="fas fa-concierge-bell"></i>
              {{ $t("Umts reception") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.sportsFacilities"><i class="fas fa-table-tennis"></i>
              {{ $t("Sports facilities") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.wgSuitable">
              <i class="fas fa-users"></i>
              {{ $t("Wg suitable") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.roomsChangeable"><i class="fas fa-person-booth"></i>
              {{ $t("Rooms changeable") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.airConditioned"><i class="far fa-snowflake"></i>
              {{ $t("Air conditioned") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.guestWC"><i class="fas fa-restroom"></i> {{ $t("Guest toilet") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.shutters"><i class="fas fa-grip-lines"></i>
              {{ $t("Roller shutters") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.gardenUse"><i class="fa fa-tree"></i> {{ $t("Garden use") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.stack"><i class="fas fa-broom"></i> {{ $t("Stack") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.dvbt"><i class="fas fa-wifi"></i> {{ $t("WIFI") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.barrierFree"><i class="fab fa-accessible-icon"></i> {{
                $t("Barrier free" )
              }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.sauna"><i class="fa-solid fa-fire"></i>
              {{ $t("Sauna") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.swimmingpool"><i class="fa fa-swimming-pool"></i>
              {{ $t("Swimmingpool") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.dvcabling"><i class="fas fa-network-wired"></i> {{ $t("Dv cabling") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.ramp">
              <i class="fas fa-stream"></i> {{ $t("Ramp") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.liftingPlatform">
              <i class="fas fa-arrow-up"></i>
              {{ $t("Lifting platform") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.crane">
              <i class="fas fa-magnet"></i> {{ $t("crane") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.guestTerrace">
              <i class="fas fa-wind"></i>
              {{ $t("Guest terrace") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.teaKitchen">
              <i class="fas fa-glass-whiskey"></i> {{ $t("teaKitchen") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.canteenCafeteria">
              <i class="fas fa-glass-whiskey"></i>
              {{ $t("Canteen cafeteria") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.breweryBinding">
              <i class="fas fa-beer"></i>
              {{ $t("Brewery binding") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.wellnessArea"><i class="fas fa-swimming-pool"></i>
              {{ $t("Wellness area") }}
            </b-form-checkbox>
          </b-col>

          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.library"><i class="fas fa-book"></i>
              {{ $t("Library") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.attic">
              <i class="fas fa-table"></i>
              {{ $t("Attic") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled   style="white-space: nowrap" inline v-model="furnishEdit.cableChannels">
              <i class="fa fa-dropbox"></i>
              {{ $t("Cable channels") }}
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" md="6" lg="3" style="padding-left:9px;padding-bottom:9px">
            <b-form-checkbox disabled  style="white-space: nowrap" inline v-model="furnishEdit.telephonevacationProperty"><i class="fa fa-phone"></i>
              {{ $t("Telephone Vacation Property") }}
            </b-form-checkbox>
          </b-col>
        </b-row>


      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mergeDeep} from "@/Plugins/custom-prototypes";

export default {
  name: 'realEstateFurniture',
  props: ['value'],
  data: () => ({
    intervalId: null,
    furnish: {
      "bath": {
        "tub": false
      },
      "kitchen": {
        "ebk": false
      },
      "floor": {
        "parquet": false
      },
      "heatingSystem": {
        "distant": false,
        "floor": false
      },
      "beacon": {
        "distant": false
      },
      "elevator": {
        "people": false
      },
      "roofShape": {
        "flat": false
      },
      "construction": {
        "massive": false
      },
      "level": {
        "turnkeywithcellar": false
      },
      "energyType": {
        "lowPower": false
      },
      "gastronomy": {
      },
      "security": {
      },
      "expansionStage": {
      },
      "furnished": {
      },
      "basement": {
      },
      "alignmentbalconyterrace": {
        "south": false
      },
    /*  "parkingType": {
        "underground": false
      },*/
      "cableSatTv": false,
      "wheelchairAccessible": false,
      "seniorFriendly": false,
      "storageRoom": false,
      "bikeRoom": false,
      "washdryroom": false,
      "conservatory": false,
      "dvbt": false,
      "umtsReception": false,
      "sportsFacilities": false,
      "wgSuitable": false,
      "roomsChangeable": false,
      "airConditioned": false,
      "guestWC": false,
      "shutters": false,
      "gardenUse": false,
    },
    furnishEdit: null,
  }),
  watch: {
    value: {
      handler() {
        this.checkAndMerge();
        this.$emit("change")
      },
      immediate: true
    },
    furnishEdit: {
      handler() {
        this.$emit('input', this.furnishEdit)
      },
      deep: true,
    }
  },
  methods: {
    checkAndMerge() {
      if (this.furnishEdit != null && (this.furnishEdit === this.value || JSON.stringify(this.value) === JSON.stringify(this.furnishEdit)))
        return;
      let mergedValue = mergeDeep(this.value, this.furnish, false)
      this.furnishEdit = {...mergedValue};
      this.$emit('input', this.furnishEdit)
    }
  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
