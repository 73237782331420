<template>
  <div id="contact">
    <b-row class="mt-1">

      <b-col cols="12" md="6" lg="2">
        <b-form-group :label="$t('salutation')" label-for="realEstate-type">
          <b-input readonly
              v-model="realEstate.contactPerson.salutation"

          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="5">
        <b-form-group :label="$t('firstname')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.firstname"
                         />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="5">
        <b-form-group :label="$t('lastname')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.lastname"  />
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('email')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.email"  />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('mobile')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.mobile"  />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('phone')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.phone"  />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('Fax')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.fax"  />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group :label="$t('Address')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.address"  />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('Zip')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.zip"  />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('city')" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" v-model="realEstate.contactPerson.city"  />
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>

export default {
  name: 'realEstateContact',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {



    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {

  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}

</style>
