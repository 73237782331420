<template>
  <div v-if="loaded">
    <b-row class="mt-1 w-100">

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('One payment') +' in %'" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.oneTimePayment"
                         />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('Discount 3-6 months') +' in %'" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.discount3"
                         />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('Discount 6-12 months') +' in %'" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.discount6"
                         />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="3">
        <b-form-group :label="$t('Discount 12+ months') +' in %'" label-for="realEstate-buy-price">
          <b-form-input readonly  id="realEstate-buy-price" type="number" v-model="realEstate.realEstateTreasure.discount12"
                         />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('AirBnb link')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.airbnbLink"
                        readonly/>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Booking link')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.bookingLink"
                        readonly/>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Vrbo link')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.vrboLink"
                        readonly/>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Agoda link')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.agodaLink"
                        readonly/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="$t('Expedia link')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.expediaLink"
                        readonly/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="$t('Treasure link')" label-for="realEstate-buy-price">
          <b-form-input id="realEstate-buy-price" v-model="realEstate.realEstateTreasure.treasureLink"
                        readonly/>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group :label="$t('Review Online')" label-for="realEstate-buy-price">
          <b-form-textarea v-model="realEstate.realEstateTreasure.review" @change="()=>onValueUpdated"
                           size="sm"></b-form-textarea>

        </b-form-group>
      </b-col>
    </b-row>
    </div>
</template>

<script>

export default {
  name: 'realEstateTreasureRent',
  components:{},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loaded: false,
  }),
  computed: {

    realEstate: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  created() {
   this.loaded = true

  },
  methods: {

  }
}
</script>

<style>
.realEstate-description .ql-editor {
  min-height: 200px;
}
</style>
